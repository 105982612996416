import React from "react";
import {
	Icon,
	IconArrowUp,
	IconArrowDown,
} from "@flixbus/honeycomb-icons-react";

export const ExpandArrow = ({ isExpanded = false }) => {
	return isExpanded ? (
		<Icon
			size={3}
			InlineIcon={IconArrowUp}
			aria-label={`Collapse details`}
			data-testid="expand-arrow-up-icon"
		/>
	) : (
		<Icon
			size={3}
			InlineIcon={IconArrowDown}
			aria-label={`Expand details`}
			data-testid="expand-arrow-down-icon"
		/>
	);
};
