import React, { useEffect, useState } from "react";
// components
import { Checkbox, DataTableRow } from "@flixbus/honeycomb-react";
import { DateAndTime, Price } from "../../../../components";

export const RefundSubItemTableRow = ({
	orderItem: subItem,
	currency = "",
	isDisabledItem = false,
	id,
	orderItemId,
	onRefundSubItemsChange = () => {},
}) => {
	return subItem ? (
		<>
			{/* TODO: replace id with item id when it's done in BE */}
			<DataTableRow
				data-testid="refund-sub-item-row"
				checkbox={
					<Checkbox
						small
						aria-label={"Select Sub Item"}
						checked={isDisabledItem ? false : subItem.isSelected}
						onChange={(e) => {
							onRefundSubItemsChange(orderItemId, subItem.id, e.target.checked);
						}}
						id={id.toString()}
						value={id.toString()}
						disabled={isDisabledItem}
					/>
				}
			>
				{/* route name */}
				<td>
					<div className="">
						<span>{subItem.fullRouteName}</span>
					</div>
				</td>
				{/* date and time info */}
				<td>
					<div className=" ">
						<DateAndTime
							timeStamp={subItem.departure}
							isDepartureType={false}
						/>
					</div>
				</td>
				{/* price info */}
				<td>
					<div className="orderPrice">
						<Price value={subItem.priceGross} currency={currency} />
					</div>
				</td>
			</DataTableRow>
		</>
	) : null;
};
