import React, { useEffect, useState } from "react";
// components
import { DataTable, DataTableHead } from "@flixbus/honeycomb-react";
import { RefundSubTableHeaders } from "../../../../constants";
import { RefundSubItemTableRow } from "../RefundSubItemTableRow/RefundSubItemTableRow";
import { isRefundableItem } from "../../../../utils";

export const RefundSubItemsTable = ({
	interconnectionItems,
	isDisabled = false,
	currency = "",
	maxRefundAmount = 0,
	orderItemId,
	onRefundSubItemsChange = () => {},
}) => {
	const [refundableOrderItems, setRefundableOrderItems] = useState([]);

	useEffect(() => {
		// filter order interconnectionItems to get only refundable interconnectionItems (price > 0 and <= max refund)
		if (
			interconnectionItems &&
			interconnectionItems.length &&
			maxRefundAmount
		) {
			setRefundableOrderItems([]);
			const filteredItems = interconnectionItems.filter(
				isRefundableItem(maxRefundAmount)
			);
			setRefundableOrderItems(filteredItems);
		}
	}, [interconnectionItems, maxRefundAmount]);

	return (
		// if refundableOrderItems is empty, return nothing
		!!refundableOrderItems && refundableOrderItems.length ? (
			<div data-testid="refund-sub-interconnectionItems-table">
				<DataTable
					scrollable
					headers={
						<DataTableHead data-testid="refund-sub-interconnectionItems-table-head">
							{RefundSubTableHeaders}
						</DataTableHead>
					}
				>
					{/* show refundable interconnectionItems only */}
					{/* TODO: replace index with orderId when it's added in BE */}
					{refundableOrderItems.map((orderItem, index) => (
						<React.Fragment key={orderItem.route + orderItem.priceGross}>
							<RefundSubItemTableRow
								orderItem={orderItem}
								orderItemId={orderItemId}
								id={index}
								currency={currency}
								isDisabledItem={isDisabled}
								isSelectedItem={orderItem.isSelected}
								onRefundSubItemsChange={onRefundSubItemsChange}
							/>
						</React.Fragment>
					))}
				</DataTable>
			</div>
		) : null
	);
};
