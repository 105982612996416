export const isRefundableItem = (maxRefundAmount = 0) => {
    return function(orderItem) {
        if (!orderItem) {
            return false;
        }
        // check if there are ICs in the orderItem
        if (orderItem.routes && orderItem.routes.length) {
            // check if any of the ICs is refundable
            return orderItem.routes.some((ic) => ic.priceGross <= maxRefundAmount && ic.priceGross > 0);
        } else {
            // check if the orderItem is refundable when there are no ICs
            return orderItem.priceGross <= maxRefundAmount && orderItem.priceGross > 0;
        }
    }
};
