import { InterconnectionStatus } from "../constants";

export const isOrderWithIC = (interconnectionId = "") => {
	if (!interconnectionId || typeof interconnectionId !== "string") {
		return false;
	}
	return (
		interconnectionId &&
		interconnectionId.toUpperCase() == InterconnectionStatus.withConnection
	);
};
