import React, { useEffect, useState } from "react";
// components
import {
	Button,
	ButtonGroup,
	DataTableRow,
	Tag,
} from "@flixbus/honeycomb-react";
import { Icon, IconEye } from "@flixbus/honeycomb-icons-react";
import { RouteName, Price, RideTag, ExpandArrow } from "../../../../components";
import { OrderItemDetails } from "..";
// utils
import { anonymizePersonalData } from "../../../../utils/anonymizePersonalData";
import { isOrderWithIC, shortenLongString } from "../../../../utils";
// styles
import "./OrderItemTableRow.scss";

export const OrderItemTableRow = ({
	orderItem,
	key,
	onCommentsPopupClick = () => {},
}) => {
	const [phone, setPhone] = useState("");
	const [lastName, setLastName] = useState("");
	const [isExpandedRow, setIsExpandedRow] = useState(false);
	const [concessionLabel, setConcessionLabel] = useState("");
	const [isIcOrder, setIsIcOrder] = useState(
		isOrderWithIC(orderItem.interconnectionId)
	);

	useEffect(() => {
		if (orderItem) {
			// update phone and lastName & anonymize data for dev environment
			let phone = !!+process.env.REACT_APP_IS_PRODUCTION
				? orderItem.passenger?.phone
				: anonymizePersonalData(orderItem.passenger?.phone, "phone");
			let lastName = !!+process.env.REACT_APP_IS_PRODUCTION
				? orderItem.passenger?.lastName
				: anonymizePersonalData(orderItem.passenger?.lastName, "name");
			setPhone(phone);
			setLastName(lastName);
			setConcessionLabel(
				orderItem.concessionOwnerName || orderItem.mandateSource?.label
			);
		}
	}, [orderItem]);

	const handleRowClick = () => {
		if (isIcOrder) {
			setIsExpandedRow(!isExpandedRow);
		}
		return;
	};

	return orderItem ? (
		<>
			<DataTableRow key={key} onClick={() => handleRowClick()}>
				<td>
					<div className="OrderItemIdCell">
						{/* show toggle only for order item rows with interconnections */}
						{isIcOrder ? (
							<Button
								extraClasses="OrderItemToggleRowButton"
								display="square"
								appearance="link"
								onClick={(event) => {
									event.stopPropagation();
									setIsExpandedRow(!isExpandedRow);
								}}
								aria-expanded={isExpandedRow}
								aria-controls={`${key}-item`}
							>
								<ExpandArrow isExpanded={isExpandedRow} />
							</Button>
						) : null}
						<span>{orderItem.id}</span>
					</div>
				</td>
				{/* concession and rideId */}
				<td>
					{orderItem.rideId ? (
						<>
							<RideTag route={orderItem} />

							{concessionLabel ? (
								<>
									<br />
									<Tag extraClasses="mt-1" small title={concessionLabel}>
										{shortenLongString(concessionLabel)}
									</Tag>
								</>
							) : null}
						</>
					) : (
						<span>N/A</span>
					)}
				</td>
				{/* route info */}
				<td>
					<div>
						<RouteName
							orderItem={orderItem}
							interconnection={orderItem.interconnectionId}
						/>
					</div>
				</td>
				{/* passenger info */}
				<td>
					<span>
						{orderItem.passenger?.firstName} {lastName}
					</span>
				</td>
				<td className="orderItemPhone">{phone}</td>
				{/* price info */}
				<td>
					<div className="orderPrice">
						<Tag small extraClasses="orderPrice__label">
							Gross
						</Tag>
						<Price value={orderItem.priceGross} currency={orderItem.currency} />
					</div>
					<div className="orderPrice">
						<Tag small extraClasses="orderPrice__label">
							Net
						</Tag>
						<Price value={orderItem.priceNet} currency={orderItem.currency} />
					</div>
				</td>
				{/* type */}
				<td>
					<span>{orderItem.type}</span>
				</td>
				{/* comments */}
				<td>
					<ButtonGroup>
						<Button
							size="sm"
							extraClasses="CommentsPopupButton"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onCommentsPopupClick(orderItem, true);
							}}
							data-testid="comments-popup-button-view"
						>
							{orderItem.comments?.length || 0}
							<Icon InlineIcon={IconEye} size={4} />
						</Button>
					</ButtonGroup>
				</td>
			</DataTableRow>
			<DataTableRow
				id={`${key}-item`}
				hidden={!isExpandedRow}
				extraClasses="OrderDetailsRow"
			>
				{/* details with interconnection and comments */}
				<OrderItemDetails orderItem={orderItem} key={key} />
			</DataTableRow>
		</>
	) : null;
};
