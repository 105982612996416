import React, { useEffect, useState } from "react";
// components
import { Checkbox, DataTable, DataTableHead } from "@flixbus/honeycomb-react";
import { RefundItemTableRow } from "../RefundItemTableRow/RefundItemTableRow";
// utils
import { isRefundableItem } from "../../../../utils";
// constants
import { RefundTableHeaders } from "../../../../constants";
// styles
import "./RefundItemsTable.scss";

const RefundItemsTable = ({
	isDisabled,
	maxRefundAmount,
	tableData = [],
	areAllSelected = false,
	onRefundSubItemsChange = () => {},
	onRefundItemsChange = () => {},
	onGlobalCheckboxChange = () => {},
}) => {
	const [refundableOrderItems, setRefundableOrderItems] = useState([]);

	useEffect(() => {
		if (tableData && tableData.length && maxRefundAmount) {
			setRefundableOrderItems([]);
			// filter order items to shown only refundable items
			const filteredItems = tableData.filter(isRefundableItem(maxRefundAmount));
			setRefundableOrderItems(filteredItems);
		}
	}, [tableData, maxRefundAmount]);

	return (
		// if refundableOrderItems is empty, return N/A
		!!refundableOrderItems && refundableOrderItems.length ? (
			<div>
				<DataTable
					scrollable
					data-testid="refund-items-table"
					headers={
						<DataTableHead
							data-testid="refund-items-table-head"
							checkbox={
								<Checkbox
									small
									aria-label="Select All"
									id="select-all"
									value="select-all"
									data-testid="select-all-items"
									onChange={(e) => {
										onGlobalCheckboxChange(e.target.checked);
									}}
									checked={isDisabled ? false : areAllSelected}
									disabled={isDisabled}
								/>
							}
						>
							{RefundTableHeaders}
						</DataTableHead>
					}
				>
					{/* show refundable items only, with price < max refund amount */}
					{refundableOrderItems.map((orderItem) => (
						<React.Fragment key={orderItem.id}>
							<RefundItemTableRow
								orderItem={orderItem}
								isDisabledItem={
									isDisabled || orderItem.priceGross > maxRefundAmount
								}
								maxRefundAmount={maxRefundAmount}
								onRefundSubItemsChange={onRefundSubItemsChange}
								onRefundItemsChange={onRefundItemsChange}
							/>
						</React.Fragment>
					))}
				</DataTable>
			</div>
		) : (
			<p>Order Items are N/A</p>
		)
	);
};

export default RefundItemsTable;
