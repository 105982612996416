import React, { useEffect, useState } from "react";
// components
import { Button, Checkbox, DataTableRow } from "@flixbus/honeycomb-react";
import { ExpandArrow, Price, RouteName } from "../../../../components";
// utils
import { isOrderWithIC } from "../../../../utils";
import { RefundSubItemsTable } from "../RefundSubItemsTable/RefundSubItemsTable";
// styles
import "./RefundItemTableRow.scss";

export const RefundItemTableRow = ({
	orderItem,
	isDisabledItem = false,
	maxRefundAmount = 0,
	onRefundSubItemsChange = () => {},
	onRefundItemsChange = () => {},
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isOrderWithICItems, setIsOrderWithICItems] = useState(
		isOrderWithIC(orderItem.interconnectionId)
	);

	return orderItem ? (
		<>
			<React.Fragment key={orderItem.route + 0}>
				<DataTableRow
					data-testid="refund-item-row"
					checkbox={
						<Checkbox
							small
							aria-label={"Select Order Item"}
							checked={isDisabledItem ? false : orderItem.isSelected}
							onChange={(e) => {
								onRefundItemsChange(orderItem.id, e.target.checked);
							}}
							id={orderItem.id}
							data-testid="select-refund-item-checkbox"
							value={orderItem.id}
							disabled={isDisabledItem}
						/>
					}
				>
					<td>
						<div className="RefundSubItemIdCell">
							{/* if order item contains IC - show icon to expand details */}
							{isOrderWithIC(orderItem.interconnectionId) ? (
								<Button
									extraClasses="OrderItemToggleRowButton"
									display="square"
									appearance="link"
									onClick={(event) => {
										event.stopPropagation();
										setIsExpanded(!isExpanded);
									}}
									aria-expanded={isExpanded}
									aria-label="expand button"
								>
									<ExpandArrow isExpanded={isExpanded} />
								</Button>
							) : null}
							<span>{orderItem.id}</span>
						</div>
					</td>
					{/* route info */}
					<td>
						<div>
							<RouteName
								orderItem={orderItem}
								interconnection={orderItem.interconnectionId}
							/>
						</div>
					</td>
					{/* passenger info */}
					<td>
						<span>
							{orderItem.passenger?.firstName} {orderItem.passenger?.lastName}
						</span>
					</td>
					{/* price info */}
					<td>
						<div className="orderPrice">
							<Price
								value={orderItem.priceGross}
								currency={orderItem.currency}
							/>
						</div>
					</td>
					{/* type */}
					<td>
						<span>{orderItem.type}</span>
					</td>
				</DataTableRow>
			</React.Fragment>
			{/* table with interconnection items */}
			<React.Fragment key={orderItem.route + 1}>
				<DataTableRow hidden={!isExpanded} extraClasses="OrderDetailsRow">
					<td colSpan={12}>
						<div className="RefundInterconnectionItemsTableWrapper">
							<RefundSubItemsTable
								interconnectionItems={orderItem.routes}
								orderItemId={orderItem.id}
								currency={orderItem.currency}
								maxRefundAmount={maxRefundAmount}
								onRefundSubItemsChange={onRefundSubItemsChange}
								onRefundItemsChange={onRefundItemsChange}
							/>
						</div>
					</td>
				</DataTableRow>
			</React.Fragment>
		</>
	) : null;
};
